<form
    [formGroup]="gluelamSchedulerForm"
    (ngSubmit)="onSubmit()"
>
    <div
        class="row"
        style="align-items: flex-end;"
    >
        <div class="flx-grow-1">
            <app-machine-selector-dropdown
                [machineGroupId]="machineGroup?.machineGroupId"
                formControlName="machineId"
            >
            </app-machine-selector-dropdown>
        </div>
        <div class="flx-grow-1">
            <kendo-formfield>
                <kendo-label translate>Scheduler.StartDate</kendo-label>
                <kendo-datepicker formControlName="startDate"></kendo-datepicker>
            </kendo-formfield>
        </div>
        <div class="flx-grow-1">
            <kendo-formfield>
                <kendo-label translate>Scheduler.EndDate</kendo-label>
                <kendo-datepicker
                    formControlName="endDate"
                >
                </kendo-datepicker>
            </kendo-formfield>
        </div>
        <div class="row">
            <button
                kendoButton
                type="submit"
                themeColor="primary"
                size="large"
                title="Prev"
                [svgIcon]="arrowLeftIcon"
                [disabled]="!gluelamSchedulerForm.valid"
                (click)="prev()"
            >
            </button>
            <button
                kendoButton
                type="submit"
                themeColor="primary"
                size="large"
                title="Next"
                [svgIcon]="arrowRightIcon"
                [disabled]="!gluelamSchedulerForm.valid"
                (click)="next()"
            >
            </button>
        </div>
        <div>
            <button
                kendoButton
                [svgIcon]="searchIcon"
                themeColor="primary"
                size="large"
                [disabled]="!gluelamSchedulerForm.valid"
                type="submit"
            >
            </button>
        </div>
    </div>
</form>
<ngx-spinner
    type="ball-beat"
    [name]="'glueLamSchedulerLoader'"
    [fullScreen]="true"
>
</ngx-spinner>
<div
    kendoTooltip
    style="width: 100%;"
>
    <svg
        *ngIf="gluePlans"
        [attr.viewBox]="viewBox"
        xmlns="http://www.w3.org/2000/svg"
        style="width: 100%;height: 90vh;"
    >

        <g appSvgCoordinateSytem
           [scaleFactorY]="4"
           [unitsMainY]="60 * 24"
           [smallFractionsY]="24"
           [unitsMainX]="5000"
           [smallFractionsX]="5"
           ></g>

        <rect
            *ngFor="let s of availableShifts"
            [attr.y]="s.y"
            [attr.width]="maxX"
            [attr.height]="s.h"
            class="avail"
            [attr.transform]="transform"
        ></rect>
        <svg
            *ngFor="let gp of gluePlans"
            [attr.x]="gp.x"
            [attr.y]="gp.y * scaleFactorY"
        >
            <rect
                [attr.width]="gp.w"
                [attr.height]="gp.h"
                id="glue-plan-{{ gp.gluePlanId }}"
                kendoPopoverAnchor
                #anchor="kendoPopoverAnchor"
                [popover]="gspopover"
                class="gluePlanBox"
                [ngStyle]="getStyle(gp)"
                [attr.transform]="transform"
            ></rect>
            <text
                x="60"
                y="300"
                [attr.width]="gp.w"
                [attr.height]="gp.h"
                class="gluePlanBox"
            >
                {{ gp.gluePlan.name }} - {{ gp.gluePlan.gluingPlanID }}
            </text>
        </svg>
    </svg>
</div>






<kendo-popover
    #gspopover
    title="{{ 'GluePlan.GluePlan' | translate }}"
    [templateData]="getContextData"
>
    <ng-template
        kendoPopoverTitleTemplate
        let-anchor
        let-data="data"
    >
        {{data.name}} {{data.gluingPlanID}}
    </ng-template>
    <ng-template
        kendoPopoverBodyTemplate
        let-anchor
        let-data="data"
    >
        <span>Length: {{data.length}}</span>
        <br>
        Offset: {{data.lenghtOffset}}
        <br>
        {{data.plannedExecutionStartDate | date}} to {{data.plannedExecutionEndDate | date}}
    </ng-template>
    <ng-template
        kendoPopoverActionsTemplate
        let-anchor
        let-data="data"
    >
        <button
            kendoButton
            themeColor="primary"
            [svgIcon]="pencilIcon"
            (click)="onGluePlanEdit(data)"
        >
        </button>
        <button
            kendoButton
            themeColor="error"
            [svgIcon]="trashIcon"
            [disabled]="true"
        >
        </button>
        <button
            kendoButton
            themeColor="info"
            [svgIcon]="printIcon"
            [routerLink]="['/glulam/glueplan-print-third', data.gluingPlanID]"
        >
        </button>
        <button
            kendoButton
            themeColor="info"
            [svgIcon]="downloadIcon"
            (click)="download(data.gluingPlanID)"
        >
        </button>
    </ng-template>
</kendo-popover>
