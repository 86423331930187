<div class="popupContent">
  <div class="div-basic-info">
    <!-- Display basic info-->
        <div>
            <kendo-label translate>GluePlanPrint.OrderId</kendo-label>:<kendo-label>{{selectedDemand?.orderID}}</kendo-label>
        </div>
        <div>
            <kendo-label translate>OrderViewTranslation.OrderRow</kendo-label>:<kendo-label>{{selectedDemand?.orderRowID}}</kendo-label>
        </div>
        <div>
            <kendo-label translate>OrderViewTranslation.CustomerDesc</kendo-label>:<kendo-label>{{selectedDemand?.customerDesc}}</kendo-label>
        </div>
        <div>
            <kendo-label translate>OrderViewTranslation.BEAM_QUALITY</kendo-label>:<kendo-label>{{selectedDemand?.visualQuality}}</kendo-label>
        </div>
        <div>
          <kendo-label translate>OrderViewTranslation.ConstructionCertification</kendo-label>:<kendo-label>{{selectedDemand?.ConstructionCertification}}</kendo-label>
      </div>
  </div>
  <div style="margin-bottom: 10px;">
    <button kendoButton [primary]="true" (click)="add()">
      {{ 'App.AddNew' | translate }}
  </button>
  </div>
  <div style="margin-bottom: 10px;">
    <!-- Demand Specification Grid-->
    <div class="grid-wrapper">
      <kendo-grid
          #demandSpecificationEditorGrid
          [columnMenu]="false"
          [data]="demandSpecificationsGridData"
          [groupable]="false"
          [resizable]="true"
          [sortable]="true"
          (edit)="editHandler($event)"
          (cancel)="cancelHandler($event)"
          (save)="saveHandler($event)"
          [size]="'small'"
          (remove)="removeHandler($event)">
        <kendo-grid-column
          title="{{ 'GluePlan.LamellaPosition' | translate }}"
          field="lamellaPosition"
          [editable]="false"
          class="align-right">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.lamellaPosition }}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          title="{{ 'GluePlan.NumberOfLams' | translate }}"
          field="numberOfLamellas"
          class="align-right">
          <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem.details.numberOfLamellas }}
          </ng-template>
          <ng-template kendoGridEditTemplate let-formGroup="formGroup">
            <kendo-numerictextbox format="n" [decimals]="0" [formControl]="formGroup.get('numberOfLamellas')" [min]="1" [max]="99"></kendo-numerictextbox>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          title="{{ 'GluePlan.LengthOfLamellas' | translate }}"
          field="lengthOfLamellas"
          class="align-right">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.details.lengthOfLamellas }}
          </ng-template>
          <ng-template kendoGridEditTemplate let-formGroup="formGroup">
            <kendo-numerictextbox format="n" [decimals]="0" [formControl]="formGroup.get('lengthOfLamellas')" [min]="1"></kendo-numerictextbox>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          title="{{ 'GluePlan.EdgeOffset' | translate }}"
          field="edgeOffset"
          class="align-right">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.details.edgeOffset }}
          </ng-template>
          <ng-template kendoGridEditTemplate let-formGroup="formGroup">
            <kendo-numerictextbox format="n" [decimals]="0" [formControl]="formGroup.get('edgeOffset')"></kendo-numerictextbox>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          title="{{ 'GluePlan.NoGlue' | translate }}"
          field="noGlue">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.details.noGlue ? ('App.Yes' | translate) : ('App.No' | translate) }}
          </ng-template>
          <ng-template kendoGridEditTemplate let-formGroup="formGroup">
            <kendo-dropdownlist
              [data]="status"
              [valuePrimitive]="true"
              valueField="value"
              textField="text"
              [formControl]="formGroup.get('noGlue')">
            </kendo-dropdownlist>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          title="{{ 'OrderViewTranslation.LamminaPlaneThickness' | translate }}"
          field="lamellaPlaningThickness"
          class="align-right">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.details.lamellaPlaningThickness | number: '1.1-2' }}
          </ng-template>
          <ng-template kendoGridEditTemplate let-formGroup="formGroup">
            <kendo-numerictextbox format="n" [decimals]="2" [formControl]="formGroup.get('lamellaPlaningThickness')" [min]="1" [max]="999"></kendo-numerictextbox>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          title="{{ 'GluePlan.Turn' | translate }}"
          field="turn">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.details.turn ? ('App.Yes' | translate) : ('App.No' | translate) }}
          </ng-template>
          <ng-template kendoGridEditTemplate let-formGroup="formGroup">
            <kendo-dropdownlist
              [data]="status"
              [valuePrimitive]="true"
              valueField="value"
              textField="text"
              [formControl]="formGroup.get('turn')">
            </kendo-dropdownlist>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
        title="{{ 'App.Material' | translate }}"
        field="materialDescription"
        [filterable]="false">
        <ng-template
          kendoGridEditTemplate
          let-dataItem="dataItem"
          let-formGroup="formGroup">
          <app-select-entity
            [formControl]="formGroup.get('materialIndex')"
            entityTypeCode="RESOURCE"
            (selectedEntity)="setSelectedMaterial($event)">
          </app-select-entity>
        </ng-template>
        <ng-template
          kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          {{dataItem.details.materialDescription}}
        </ng-template>
      </kendo-grid-column>
        <kendo-grid-command-column title="{{ 'Operative.Command' | translate }}" [width]="220">
          <ng-template kendoGridCellTemplate let-dataItem let-isNew="isNew">
            <button kendoGridEditCommand [primary]="true">
              {{ 'App.Edit' | translate }}
            </button>
            <button kendoGridSaveCommand themeColor="primary" [disabled]="demandSpecForm?.invalid">
              {{ isNew ? ('App.Add' | translate) : ('App.Update' | translate) }}
            </button>
            <button kendoGridRemoveCommand themeColor="error">
              {{ 'App.Remove' | translate}}
           </button>
            <button kendoGridCancelCommand themeColor="error">
              {{ isNew ? ('App.Discard' | translate) : ('App.Cancel' | translate) }}
            </button>
          </ng-template>
        </kendo-grid-command-column>
      </kendo-grid>
      <div class="k-i-loading" *ngIf="loading"></div>
    </div>
  </div>
  </div>
  <kendo-dialog-actions>
    <div class="flex-grow"></div>
    <button kendoButton themeColor="error" type="button" (click)="close()">
      {{ 'App.Close' | translate }}
    </button>
    <button kendoButton themeColor="primary" type="submit" (click)="save()" [disabled]="disableSaveBtn">
        {{ 'App.Save' | translate }}
    </button>
  </kendo-dialog-actions>
