import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { KendoGridService, StateFilters } from '@app/core/services/custom-services/kendo-grid.service';

import { CreateGlulamSpecForm } from '@app/core/models/forms/gluelam/glulam-spec/create-glulam-spec-form.model';
import { EntityFormComponent } from '@app/modules/entity-admin/entity-form/entity-form.component';
import { FormGroup } from '@angular/forms';
import { GlulamSpecComponent } from '@app/modules/gluelam/controls/glulam-spec/glulam-spec.component';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Guid } from '@app/core/models/Guid';
import { IEntityGroup } from '../../custom-services/entity-group.service';
import { ISawPatternDTO } from '@app/core/models/sawPatternDTO.model';
import { ISawPatternHeaderDTO } from '@app/core/models/saw-pattern-header.model';
import { IUnitConversion } from '@app/core/models/unitConversion.model';
import { Injectable } from '@angular/core';
import { State } from '@progress/kendo-data-query';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../../environments/environment';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

marker('EntityTranslation.NewResource');
marker('EntityTranslation.NewActivity');
marker('EntityTranslation.UpdateResource');
marker('EntityTranslation.UpdateActivity');

@Injectable({
  providedIn: 'root'
})
export class EntityService extends BehaviorSubject<GridDataResult> {
  loading: boolean = false;
  private _entityGroupIndex: number;

  constructor(
    private readonly http: HttpClient,
    private readonly dialogService: DialogService,
    private readonly kendoGridService: KendoGridService,
    private readonly translateService: TranslateService
  ) { super(null);}

  query(state: State) {
    const filter = this.kendoGridService.getStateFilter(state);
    filter.id = this._entityGroupIndex;

    this.loading = true;
    this.http.post<GridDataResult>(`${environment.apiUrl}Entity/getEntities`, JSON.stringify(filter), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).subscribe(r => {
      super.next(r);
      this.loading = false;
    });
  }

  update(entity: IEntity): Observable<any> {
    return this.http.put<IEntity>(`${environment.apiUrl}Entity/${entity.index}`, entity);
  }

  insert(entity: IEntity): Observable<IEntity> {
    return this.http.post<IEntity>(`${environment.apiUrl}Entity`, entity);
  }

  openDialog(entity: IEntity, entityGroup: IEntityGroup, isNew: boolean, entityTypeCode: string): DialogRef {
    let title = '';
    if (entityTypeCode == 'RESOURCE') {
      title = isNew ? this.translateService.instant('EntityTranslation.NewResource') : this.translateService.instant('EntityTranslation.UpdateResource');
    } else if (entityTypeCode == 'ACTIVITY') {
      title = isNew ? this.translateService.instant('EntityTranslation.NewActivity') : this.translateService.instant('EntityTranslation.UpdateActivity');
    }
    const dialogRef = this.dialogService.open({
      content: EntityFormComponent,
      title: title,
      width: '1700px',
    });

    const content = dialogRef.content.instance as EntityFormComponent;
    content.data = { entity, entityGroup, isNew, entityTypeCode };

    return dialogRef;
  }

  setSelectedGroup(entityGroupCode: number) {
    this._entityGroupIndex = entityGroupCode;
  }

  get(entityIndex: number, dontExpandPropertys: boolean = false): Observable<IEntity> {
    return this.http.get<IEntity>(`${environment.apiUrl}Entity/getEntity`, {
      params: new HttpParams()
        .set('index', entityIndex.toString())
        .set('dontExpandPropertys', dontExpandPropertys)
    });
  }

  find(filter: StateFilters) {
    return this.http.post<GridDataResult>(`${environment.apiUrl}Entity/getEntities`, JSON.stringify(filter), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  searchEntities(entityTypeCode: string, code: string, take: number, getMaterialStandardProperties: boolean = false): Observable<IEntity[]> {
    return this.http.get<IEntity[]>(`${environment.apiUrl}Entity/searchEntities`, {
      params: new HttpParams()
        .set('entityTypeCode', entityTypeCode)
        .set('code', code)
        .set('take', take.toString())
        .set('getMaterialStandardProperties', getMaterialStandardProperties)
    });
  }

  getEntitiesThatProduce(productIndex: number, direction: number): Observable<IEntity[]> {
    return this.http.get<IEntity[]>(`${environment.apiUrl}Entity/getEntitiesThatProduce`, {
      params: new HttpParams()
        .set('productIndex', productIndex.toString())
        .set('direction', direction.toString())
    });
  }

  openDialogForGlulamSpec(entity: IEntity, form: FormGroup<CreateGlulamSpecForm>): DialogRef {
    const dialogRef = this.dialogService.open({
      content: GlulamSpecComponent,
      width: '1000px',
      height: '600px',
    });

    const content = dialogRef.content.instance as GlulamSpecComponent;

    content.setup(entity, form)

    return dialogRef;
  }

  async getEntityReport(entityTypeCode: string): Promise<Blob> {
    return await lastValueFrom(this.http.get<Blob>(`${environment.apiUrl}Entity/getEntityReport`, {
        params: new HttpParams().set('entityTypeCode', entityTypeCode),
        responseType: 'blob' as 'json'
    }));
  }

  getPattern(index: number) {
    return this.http.get<ISawPatternDTO>(`${environment.apiUrl}SawPattern/getPattern/${index}`);
  }

  getSawPatternTable(index: number) {
    return this.http.get<ISawPatternHeaderDTO>(`${environment.apiUrl}SawPattern/getSawPatternData/${index}`);
  }

  calcGluSpec(index: number):Observable<IGlulamSpecCalcResult> {
    return this.http.get<IGlulamSpecCalcResult>(`${environment.apiUrl}GluSpec/${index}`);
  }
}

export interface IEntity {
  baseUnitCode: string;
  changeDate: string | null;
  code: string;
  commentId: number | null;
  description: string;
  entityGroupCode: string;
  index: number;
  isDynamicActivity?: boolean | null;
  sortOrderIndex: number | null;
  status: number | null;
  superiorEntityIndex?: number | null;
  userCode: string;
  machineId: number;
  propertyValues: IPropertyValue[];
  entityRelations: IEntityRelation[];
  unitConversions: IUnitConversion[];
  entityGroup?: IEntityGroup;
  targetMinStock: number | null;
  targetMaxStock: number | null;
  batchSize: number | null;
  guid: string;
  gluSpecHightMin?: number | null;
  gluSpecHightMax?: number | null;
  gluSpecTopPercentage?: number | null;
  gluSpecBottomPercentage?: number | null;
  gluSpecAddedCalcHight?: number | null;
  standardProperties?: IStandardProperties;
  beamLamminaDimensionId:number | null;
}

export interface IPropertyValue {
  id: number | null;
  entityIndex: number;
  propertyCode: string;
  valueInt: number | null;
  valueLongInt: number | null;
  valueDouble: number | null;
  valueString: string;
  userCode: string;
  changeDate: string | null;
  dataType: number;
}
export interface IEntityRelation {
  productIndex: number;
  activityIndex: number;
  relation: number;
  direction: EntityRelationDirection;
  unitCode: string;
  userCode: string;
  ChangeDate: string | null;

  productCode: string;
  activityCode: string;
  isNew: boolean;
  isChange: boolean;
  isRemoved: boolean;
  isUnlockQtyRelation: boolean;
  isUnlockUOMRelation: boolean;

  inTactical: boolean;
  inOperative: boolean;
  isChaining: boolean;
  product: IEntity;
  activity: IEntity;
  plannedQty: number;
  reportedQty: number;
  glulamSpec: IGlulamSpec[];
  forecastVolume: number;
}

export interface IGlulamSpecCalcResult{
  lams: IGlulamSpec[];
}

export enum EntityRelationDirection {
  PRODUCE = 0,
  CONSUMES = 1
}

export interface DemandStateFilter extends StateFilters {
  showPlanned: boolean;
  showOnlyWithNoGluPlan: boolean;
}

export interface EntityFilter extends StateFilters {
  siteId: Guid;
  rawLamellaThickness: number;
}

export interface IGlulamSpec {
  activityIndex: number;
  productIndex: number;
  sequence: number;
  numberOfLammellas: number;
  numberOfLammellasCalc?: number;
  planingThickness: number;
  heightDependant: boolean;
  dryJoint: boolean;
  turn: boolean;
  isNew: boolean;
  isChanged: boolean;
  isRemoved: boolean;
  layerType: number;
}

export interface IStandardProperties {
  length: number | null;
  thickness: number | null;
  width: number | null;
  species: string;
  quality: string;
}
