
<h1>{{ 'UserAdmin.Users' | translate }}</h1>
<div class="row" style="margin-bottom: ;">

</div>
<button kendoButton (click)="heal()" themeColor="primary" [svgIcon]="heartIcon"></button>

<kendo-grid
    [data]="users | async"
    [loading]="userQueryService.loading"
    [pageable]="false"
    size="small"
    (edit)="onEdit($event)"
>
    <kendo-grid-column
        field="userId"
        title="{{'UserAdmin.UserId' | translate }}"
        [width]="200"
    >
    </kendo-grid-column>
    <kendo-grid-column
        field="entraId"
        title="{{'UserAdmin.EntraId' | translate }}"
        [width]="300"
    >
    </kendo-grid-column>
    <kendo-grid-column
        field="userName"
        title="{{'UserAdmin.Name' | translate }}"
        [width]="200"
    >
    </kendo-grid-column>
    <kendo-grid-column
        field="email"
        title="{{'UserAdmin.Email' | translate }}"
        [width]="300"
    ></kendo-grid-column>
    <kendo-grid-column
        field="languageCode"
        title="{{'UserAdmin.LanguageCode' | translate }}"
        [width]="150"
    ></kendo-grid-column>
    <kendo-grid-column
        field="entraLanguage"
        title="{{'UserAdmin.EntraLanguageCode' | translate }}"
        [width]="150"
    ></kendo-grid-column>
    <kendo-grid-column
        field="changeDate"
        format="date"
        title="{{'UserAdmin.ChangeDate' | translate }}"
        [width]="300"
    ></kendo-grid-column>
    <kendo-grid-command-column [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem>
        <button kendoGridEditCommand [disabled]="!dataItem.userId" themeColor="primary" [svgIcon]="pencilIcon"></button>
     </ng-template>
    </kendo-grid-command-column>
</kendo-grid>
