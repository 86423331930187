<form [formGroup]="gluelamSchedulerForm" (ngSubmit)="onSubmit()">
  <div class="row" style="align-items: flex-end;">
    <div class="flx-grow-3">
        <app-machine-selector-dropdown [machineGroupId]="machineGroup?.machineGroupId" formControlName="machineId" >
        </app-machine-selector-dropdown>
    </div>

    <div class="flx-grow-1">
      <kendo-formfield>
        <kendo-label translate>Scheduler.StartDate</kendo-label>
        <kendo-datetimepicker formControlName="startDate">
        </kendo-datetimepicker>
      </kendo-formfield>
    </div>

    <div class="flx-grow-1">
      <kendo-formfield>
        <kendo-label translate>Scheduler.EndDate</kendo-label>
        <kendo-datetimepicker  [max]="maxDate" formControlName="endDate">
        </kendo-datetimepicker>
      </kendo-formfield>
    </div>
    <div class="row">
      <button kendoButton
              type="submit"
              themeColor="primary"
              size="large"
              title="Prev"
              [svgIcon]="arrowLeftIcon"
              [disabled]="!gluelamSchedulerForm.valid"
              (click)="prev()">
      </button>
      <button kendoButton
              type="submit"
              themeColor="primary"
              size="large"
              title="Next"
              [svgIcon]="arrowRightIcon"
              [disabled]="!gluelamSchedulerForm.valid"
              (click)="next()" >
      </button>
    </div>

    <div class="flx-grow-1">
      <kendo-formfield>
        <kendo-label translate>Scheduler.TimeResolution</kendo-label>
        <kendo-dropdownlist [data]="timeSlots" textField="text" valueField="value" [valuePrimitive]="true"
          formControlName="timeResolution">
        </kendo-dropdownlist>
      </kendo-formfield>
    </div>

    <div class="flx-grow-1">
      <kendo-formfield>
        <kendo-label translate>Scheduler.LengthResolution</kendo-label>
        <kendo-dropdownlist [data]="lengthSlots" textField="text" valueField="value" [valuePrimitive]="true"
        formControlName="lengthResolution">
      </kendo-dropdownlist>
      </kendo-formfield>
    </div>

    <div>
      <button
        kendoButton
        [svgIcon]="searchIcon"
        themeColor="primary"
        size="large"
        [disabled]="!gluelamSchedulerForm.valid"
        type="submit">
      </button>
    </div>
  </div>
</form>

<ngx-spinner type="ball-beat" [name]="'glueLamSchedulerLoader'" [fullScreen]="true">
</ngx-spinner>
<div oncontextmenu="return false;" class="k-d-flex k-h-full k-overflow-auto">
  <kendo-chart
      #kChart
      (render)="onRender()"
      [ngStyle]="{ 'minHeight.px': chartHeight }"
      (legendItemClick)="onLegendItemClick($event)"
      (seriesClick)="onSeriesClick($event)"
      class="gluePlanSchedulerKendoChart"
      *ngIf="data">
     <kendo-chart-series>
      <kendo-chart-series-item
        type="heatmap"
        xField="x"
        yField="y"
        [data]="data"
        [color]="color"
        [labels]="{ visible: false }"
        [highlight]="{visible:true}"
        [markers]="{ type: 'rect', border: { width: 1 } }">
        <kendo-chart-series-item-tooltip
        background="white">
          <ng-template let-value="value" let-category="category" let-dataItem="dataItem">
            <span *ngIf="dataItem.ids?.length">
            <div [innerHTML]="getToolTipData(dataItem)">
            </div>
            </span>
          </ng-template>
        </kendo-chart-series-item-tooltip>
      </kendo-chart-series-item>
    </kendo-chart-series>

    <kendo-chart-x-axis>
      <kendo-chart-x-axis-item [labels]="{ content: lenghtLabelContent }">
      </kendo-chart-x-axis-item>
    </kendo-chart-x-axis>

    <kendo-chart-y-axis>
      <kendo-chart-y-axis-item [labels]="{ content: dateLabelContent }">
      </kendo-chart-y-axis-item>
    </kendo-chart-y-axis>
  </kendo-chart>

  <kendo-contextmenu #chartmenu>
    <ng-template kendoContextMenuTemplate>
      <div class="gluePlanContextMenu">
      <button
        kendoButton
        themeColor="primary"
        [svgIcon]="pencilIcon"
        size="large"
        title = "Edit"
        (click)="onGluePlanEdit()">
      </button>
      <button
        kendoButton
        themeColor="error"
        [svgIcon]="trashIcon"
        size="large"
        [disabled]="true">
      </button>
      <button
        kendoButton
        themeColor="info"
        [svgIcon]="printIcon"
        size="large"
        title = "print"
        [routerLink]="['/glulam/glueplan-print-third', selectedGluePlan.gluingPlanID]"
        >
      </button>
      <button
        kendoButton
        themeColor="info"
        [svgIcon]="downloadIcon"
        size="large"
        (click)="download()">
      </button>
      </div>
    </ng-template>
  </kendo-contextmenu>
</div>
<div kendoDialogContainer></div>

