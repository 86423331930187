import { Component, Inject, Injectable, OnInit } from '@angular/core';
import { BeamMaterialLine } from '@app/core/models/beam-material-line.model';
import { CopyService, DataForCutBM, DataForCutBML } from '../../../../core/services/custom-services/copy.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RegexHelper } from '@app/shared/helpers/regex-helper';
import { CommonHelper } from '@app/shared/helpers/common-helper';
import { AddLineParameter } from '@app/core/models/demand-line.model';
import { DeamndService } from '@app/core/services/http-services/gluelam/demand.service';
import { BMLMenuOverlayRef } from './BMLMenuOverlayRef';
import { BML_COPY_DATA, BML_DATA, BM_DATA, IS_STATICAL_GLUE_PLAN, MACHINE_DATA } from './tookens';
import { BeamMaterial } from '../../../../core/models/beam-material.model';
import { IMachineDTO } from '@app/core/models/machineDTO';
import { SVGIcon, arrowUpIcon, arrowDownIcon, plusIcon, minusIcon, cutIcon, clipboardIcon } from '@progress/kendo-svg-icons';
import { CreateTestPieceForm } from '@app/core/models/forms/gluelam/beam-material-gluset/bml-menu/create-test-piece-form.model';

@Component({
  selector: 'bml-menu',
  styleUrls: ['./beam-material-glueset.component.css'],
  templateUrl: './bml-menu.component.html'
})
@Injectable()
export class BMLMenuComponent implements OnInit {
  public hasPaste: boolean = false;
  public showTestPscSection: boolean = false;
  testPieceForm: FormGroup = new FormGroup({});

  public arrowUpIcon:SVGIcon = arrowUpIcon;
  public arrowDownIcon:SVGIcon = arrowDownIcon;
  public plusIcon:SVGIcon = plusIcon;
  public minusIcon:SVGIcon = minusIcon;
  public cutIcon:SVGIcon = cutIcon;
  public clipboardIcon:SVGIcon = clipboardIcon;


  constructor(
    public dialogRef: BMLMenuOverlayRef,
    @Inject(BML_DATA) public bml: BeamMaterialLine,
    @Inject(BML_COPY_DATA) public bmlsCopied: BeamMaterialLine[],
    @Inject(BM_DATA) public bm: BeamMaterial,
    @Inject(MACHINE_DATA) public machine: IMachineDTO,
    @Inject(IS_STATICAL_GLUE_PLAN) public isStaticalGluePlan: boolean,
    public beamOrderRowService: DeamndService,
    public cs: CopyService
  ) {

    this.testPieceForm = new FormGroup<CreateTestPieceForm>({
      testPscValue: new FormControl<number>(this.machine?.testPieceLength ?? 80, [Validators.required, Validators.pattern(RegexHelper.onlyPostiveWithDotComma)])
    });

  }

  ngOnInit(): void {
    this.hasPaste = this.cs.hasPaste;
  }

  public canAdd() {
    return this.beamOrderRowService.beamOrderLines.length === 1
           && this.beamOrderRowService.beamOrderLines[0].beamMaterialType.isNormal
           && (!this.bm || this.bm.beamMaterialType.isNormal)
           && !this.cs.activeSave;
  }

  onAdd() {
    this.cs.activeSave = true;

    const bol = this.beamOrderRowService.beamOrderLines[0];

    if (this.bm != null) {
      this.dialogRef.onAddLineToBM.emit(<AddLineParameter> { beamMaterialLine: this.bml, demand: bol, IsPasted: false });
      this.dialogRef.close();
    } else {
      this.dialogRef.onAddLineToBMGS.emit(<AddLineParameter> { demand: bol, IsPasted: false });
    }
  }

  onUpp() {
    this.dialogRef.onMoveUppBeamMaterial.emit(this.bm);
  }

  onDown() {
    this.dialogRef.onMoveDownBeamMaterial.emit(this.bm);
  }

  onCut() {
    this.preapareForCopy();

    this.bml.IsDataCut = true;
    this.bml.IsDataPasted = false;
    this.cs.cutDataBML = new DataForCutBML(
      [this.bml],
      this.bm,
      null
    );

    this.hasPaste = this.cs.hasPaste;

    this.dialogRef.onCutBeamMaterialLine.emit(this.cs.cutDataBML);

    this.dialogRef.onRemoveBeamMaterialLine.emit(this.bml);

    if (this.bm.beamMaterialLines.length === 0) {
      // raise remove BM event
      this.dialogRef.onRemoveBeamMaterial.emit(this.bm);
    }

    this.dialogRef.close();
  }

  onCutBM() {
    this.preapareForCopy();

    this.cs.cutDataBM = new DataForCutBM([this.bm], null);

    this.hasPaste = this.cs.hasPaste;

    this.dialogRef.onCutBeamMaterial.emit(this.cs.cutDataBM);
    this.dialogRef.onRemoveBeamMaterial.emit(this.bm);

    this.dialogRef.close();
  }

  onCopy() {
    this.preapareForCopy();

    this.cs.cutDataBML = new DataForCutBML([this.bml], this.bm, null);
    this.dialogRef.close();
  }

  onCopyBM() {
    this.preapareForCopy();
    this.cs.cutDataBM = new DataForCutBM([this.bm], null);
    this.dialogRef.close();
  }

  preapareForCopy() {
    // this.cs.reset();
  }

  onPaste() {
    if (this.cs.cutDataBML) {
      this.dialogRef.onPasteCutBeamMaterialLine.emit(this.cs.cutDataBML);
    } else if (this.cs.cutDataBM) {
      this.dialogRef.onPasteCutBeamMaterial.emit(this.cs.cutDataBM);
    } else {
      console.error('Nothing yo paste');
    }

    this.dialogRef.close();
  }

  onRemoveMaterial() {
    this.dialogRef.onRemoveBeamMaterial.emit(this.bm);
    this.dialogRef.close();
  }

  onRemoveMaterialLine() {
    this.dialogRef.onRemoveBeamMaterialLine.emit(this.bml);
    this.dialogRef.close();
  }

  onAddTestPsc() {
    this.showTestPscSection = false;
    this.bm.takeTestPsc = true;
    this.bm.testPscLenght = Number(CommonHelper.replaceCommawithDot(this.testPieceForm.get('testPscValue').value));
    this.dialogRef.onAddTestPsc.emit(this.bm);
  }

  showTestPscForm() {
    this.showTestPscSection = true;
  }

  onRemoveTestPsc() {
    this.showTestPscSection = false;
    this.bm.takeTestPsc = false;
    this.bm.testPscLenght = 0;
    this.dialogRef.onRemoveTestPsc.emit(this.bm);
  }

  onCancel() {
    this.showTestPscSection = false;
  }

  onManualBeamMaterialAdd() {
    this.dialogRef.onAddManualBeamMaterialToBMGS.emit([...this.beamOrderRowService.beamOrderLines]);
    this.dialogRef.close();
  }

  canManualBeamMaterialAdd() {

    if(!this.beamOrderRowService.beamOrderLines || this.beamOrderRowService.beamOrderLines.length == 0)
      return false;

    return true;

    if(!this.beamOrderRowService.beamOrderLines.every(beamOrderLine => beamOrderLine.materialIndex === this.beamOrderRowService.beamOrderLines[0].materialIndex))
      return false;
    // need to check if same material index validates below properties as well

    if(!this.beamOrderRowService.beamOrderLines.every(beamOrderLine => beamOrderLine.width === this.beamOrderRowService.beamOrderLines[0].width))
      return false;

    if(!this.beamOrderRowService.beamOrderLines.every(beamOrderLine => beamOrderLine.DeafultLamminaPlaneThicknes ===  this.beamOrderRowService.beamOrderLines[0].DeafultLamminaPlaneThicknes))
      return false;

    if(!this.beamOrderRowService.beamOrderLines.every(beamOrderLine => beamOrderLine.NumberOfLaminas === this.beamOrderRowService.beamOrderLines[0].NumberOfLaminas))
      return false;

    if(!this.beamOrderRowService.beamOrderLines.every(beamOrderLine => beamOrderLine.species === this.beamOrderRowService.beamOrderLines[0].species))
      return false;

    if(!this.beamOrderRowService.beamOrderLines.every(beamOrderLine => beamOrderLine.ConstructionCertification === this.beamOrderRowService.beamOrderLines[0].ConstructionCertification))
      return false;

    return true;
  }
}
