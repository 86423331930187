
<form
    [formGroup]="entityGroupForm"
    (ngSubmit)="onSubmit()">
    <div>
        <div class="row">
            <div
                class="column"
                style="flex-grow: 1;margin-right: 10px;">
                <h3 translate>EntityTranslation.Fields</h3>
                <kendo-formfield>
                    <kendo-label translate>EntityTranslation.Code</kendo-label>
                    <kendo-textbox formControlName="code"></kendo-textbox>
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label translate>EntityTranslation.Description</kendo-label>
                    <kendo-textbox formControlName="description"></kendo-textbox>
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label translate>EntityTranslation.EntityTypeCode</kendo-label>
                    <kendo-dropdownlist
                        formControlName="entityTypeCode"
                        [data]="entityTypeCode"
                        valueField="value"
                        textField="text"
                        [valuePrimitive]="true">
                    </kendo-dropdownlist>
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label translate>EntityTranslation.ResourceTypeCode</kendo-label>
                    <kendo-dropdownlist
                        formControlName="resourceTypeCode"
                        [data]="resourceTypeCodes"
                        valueField="value"
                        textField="text"
                        [valuePrimitive]="true">
                    </kendo-dropdownlist>
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label translate>EntityTranslation.BaseUnitCode</kendo-label>
                    <kendo-dropdownlist
                        [data]="units"
                        [textField]="'description'"
                        [valueField]="'unitCode'"
                        formControlName="defaultBaseUnitCode"
                        [valuePrimitive]="true">
                    </kendo-dropdownlist>
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label translate>EntityTranslation.MachineGroup</kendo-label>
                    <kendo-dropdownlist
                        [data]="machinegroups"
                        [textField]="'name'"
                        [valueField]="'machineGroupId'"
                        formControlName="machineGroupId"
                        [valuePrimitive]="true">
                    </kendo-dropdownlist>
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label translate>EntityTranslation.Producelabel</kendo-label>
                    <app-stock-location-selector
                    formControlName="stockLocationProduceId"
                    [showLable]="false"
                    >
                </app-stock-location-selector>
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label translate>EntityTranslation.Consumelabel</kendo-label>
                    <app-stock-location-selector
                        formControlName="stockLocationConsumeId"
                        [showLable]="false"
                        >
                    </app-stock-location-selector>
                </kendo-formfield>
                <div class="row">
                <kendo-formfield>
                  <kendo-label translate>EntityTranslation.IsGlulamGroup</kendo-label>
                  <kendo-switch formControlName="isGlulamGroup"></kendo-switch>
                </kendo-formfield>
                <kendo-formfield>
                  <kendo-label translate>EntityTranslation.Tactical</kendo-label>
                  <kendo-switch formControlName="inTactical"></kendo-switch>
                </kendo-formfield>
                <kendo-formfield>
                  <kendo-label translate>EntityTranslation.Operative</kendo-label>
                  <kendo-switch formControlName="inOperative"></kendo-switch>
                </kendo-formfield>
                </div>
                <div class="padding-top-10">
                  <app-site-multi-select formControlName="siteIds"></app-site-multi-select>
                </div>
          </div>
            <div
                class="column"
                style="flex-grow: 1;"
                *ngIf="entityGroupProperties">
                <div>
                  <h3 translate style="float: left;">EntityTranslation.EntityGroupProperties</h3>
                  <h3 translate style="float: right; padding-right: 150px">EntityTranslation.UsePropertyForInstructionLabel</h3>
                </div>
                <table formArrayName="entityGroupProperties">
                    <thead>
                        <th
                            style="text-align: left;"
                            translate>
                            AppComponent.Admin_EntityProperty
                        </th>
                        <th
                            style="text-align: left;"
                            translate>
                            EntityTranslation.Default
                        </th>
                        <th
                            style="text-align: left;"
                            translate>
                            EntityTranslation.IsMandatory
                        </th>
                        <th
                            style="text-align: left;"
                            translate>
                            EntityTranslation.IsLenght
                        </th>
                        <th
                            style="text-align: left;"
                            translate>
                            EntityTranslation.IsThickness
                        </th>
                        <th
                            style="text-align: left;"
                            translate>
                            EntityTranslation.IsWidth
                        </th>
                        <th
                            style="text-align: left;"
                            translate>
                            EntityTranslation.IsSpecies
                        </th>
                        <th
                            style="text-align: left;"
                            translate>
                            EntityTranslation.IsQuality
                        </th>
                        <th></th>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let lessonForm of entityGroupProperties.controls; let i = index">
                            <tr
                                [formGroup]="getAsFormGroup(lessonForm)"
                                *ngIf="!getAsFormGroup(lessonForm)?.value?.isRemoved">
                                <td>
                                    {{ getAsFormGroup(lessonForm)?.value?.propertyCode }}
                                </td>
                                <td>
                                    <kendo-textbox formControlName="defaultValue"></kendo-textbox>
                                </td>
                                <td>
                                    <kendo-switch formControlName="isMandatory"></kendo-switch>
                                </td>
                                <td>
                                    <kendo-switch formControlName="isLenght"></kendo-switch>
                                </td>
                                <td>
                                    <kendo-switch formControlName="isThickness"></kendo-switch>
                                </td>
                                <td>
                                    <kendo-switch formControlName="isWidth"></kendo-switch>
                                </td>
                                <td>
                                    <kendo-switch formControlName="isSpecies"></kendo-switch>
                                </td>
                                <td>
                                    <kendo-switch formControlName="isQuality"></kendo-switch>
                                </td>
                                <td>
                                  <button
                                        kendoButton
                                        [svgIcon]="trashIcon"
                                        themeColor="error"
                                        (click)="removeProperty(i)"
                                        title="{{'EntityTranslation.Remove' | translate}}">
                                  </button>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
      </div>
</form>
<kendo-dialog-actions kendo-dialog-action>
  <div class="flex-grow"></div>
  <button
      kendoButton
      (click)="cancleGroupUpdate()"
      [themeColor]="'error'">
      {{ 'EntityTranslation.Cancle' | translate }}
  </button>
  <button
      kendoButton
      type="submit"
      [disabled]="!entityGroupForm.valid"
      *ngIf="isNew"
      [primary]="true"
      (click)="onSubmit()">
      {{ 'App.Add' | translate }}
  </button>
  <button
      kendoButton
      type="submit"
      [disabled]="!entityGroupForm.valid"
      *ngIf="!isNew"
      [primary]="true"
      (click)="onSubmit()">
      {{ 'App.Update'| translate }}
  </button>
</kendo-dialog-actions>
