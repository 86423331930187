<kendo-dialog-titlebar kendo-dialog-title>
  <div translate>GluePlan.GlulamSpec</div>
</kendo-dialog-titlebar>
<form id="gluSpecForm" [formGroup]="gluSpecForm" *ngIf="gluSpecForm" (ngSubmit)="onSubmit()">
  <div class="row" style="margin-bottom: 20px;">
    <kendo-formfield>
      <kendo-label [for]="HightMin" text="{{ 'GluePlan.HightMin' | translate }}"></kendo-label>
      <kendo-numerictextbox #HightMin formControlName="hightMin" [decimals]="1" format="n1">
      </kendo-numerictextbox>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="HightMax" text="{{ 'GluePlan.HightMax' | translate }}"></kendo-label>
      <kendo-numerictextbox #HightMax formControlName="hightMax" [decimals]="1" format="n1">
      </kendo-numerictextbox>
      <kendo-formerror *ngIf="gluSpecForm?.controls?.hightMax?.errors?.maxLargerMin" translate>
        GlulamSpec.MinLargerThenMax
      </kendo-formerror>
    </kendo-formfield>
  </div>
  <div class="row" style="margin-bottom: 20px;">
    <kendo-formfield>
      <kendo-label [for]="HightMin" text="{{ 'GluePlan.TopPercentage' | translate }}"></kendo-label>
      <kendo-numerictextbox #HightMin formControlName="topPercentage" [decimals]="3" format="#.###%">
      </kendo-numerictextbox>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="HightMin" text="{{ 'GluePlan.BottomPercentage' | translate }}"></kendo-label>
      <kendo-numerictextbox #HightMin formControlName="bottomPercentage" [decimals]="3" format="#.###%">
      </kendo-numerictextbox>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="HightMin" text="{{ 'GluePlan.AddedCalcHight' | translate }}"></kendo-label>
      <kendo-numerictextbox #HightMin formControlName="addedCalcHight" [decimals]="1" format="n1">
      </kendo-numerictextbox>
    </kendo-formfield>
  </div>
  <table>
    <thead>
      <th translate>GluePlan.Entity</th>
      <th translate>GluePlan.LayerType</th>
      <th translate>GluePlan.NumberOfLams</th>
      <th translate>GluePlan.Calc</th>
      <th translate>GluePlan.PlaningThickness</th>
      <th translate>GluePlan.NoGlue</th>
      <th translate>GluePlan.HightDependent</th>
      <th translate>GluePlan.Turn</th>
      <th translate>App.Action</th>
    </thead>
    <tbody>


      <ng-container formArrayName="specrows">
        <tr [formGroupName]="i" *ngFor="let r of getActiveLines() let i = index">
          <td>
            <kendo-formfield>
              <kendo-dropdownlist id="prodList" [data]="productList" textField="value" valueField="key"
                [loading]="loading" [valuePrimitive]="true" formControlName="productIndex">
              </kendo-dropdownlist>
            </kendo-formfield>
          </td>
          <td>
            <kendo-formfield>
              <kendo-dropdownlist [data]="layerTypes" textField="text" valueField="value" [loading]="loading"
                [valuePrimitive]="true" formControlName="layerType">
                <ng-template kendoDropDownListItemTemplate let-dataItem>
                  {{ dataItem.text | translate }}
                </ng-template>
                <ng-template kendoDropDownListValueTemplate let-dataItem>
                  {{ dataItem.text | translate }}
                </ng-template>
              </kendo-dropdownlist>
            </kendo-formfield>
          </td>
          <td>
            <kendo-formfield>
              <kendo-numerictextbox formControlName="numberOfLammellas" [decimals]="0" format="n0">
              </kendo-numerictextbox>
            </kendo-formfield>
          </td>
          <td>
            <kendo-loader *ngIf="calculating"></kendo-loader>
            {{ glulamSpecs[i]?.numberOfLammellasCalc ?? "n/s" }}
          </td>
          <td>
            <kendo-formfield>
              <kendo-numerictextbox formControlName="planingThickness" [decimals]="2" format="n2">
              </kendo-numerictextbox>
            </kendo-formfield>
          </td>
          <td class="center">
            <kendo-formfield>
              <kendo-switch formControlName="dryJoint"></kendo-switch>
            </kendo-formfield>
          </td>
          <td>
            <kendo-formfield>
              <kendo-switch formControlName="heightDependant"></kendo-switch>
            </kendo-formfield>
          </td>
          <td>
            <kendo-formfield>
              <kendo-switch formControlName="turn" (valueChange)="onTurnChange(r)"></kendo-switch>
            </kendo-formfield>
          </td>
          <td>
            <button kendoButton type="button" (click)="removeRow(i)" themeColor="error" [svgIcon]="minusIcon">
            </button>
          </td>
        </tr>
        <tr>
          <td colspan="3"></td>
          <td>
            <button kendoButton type="button" (click)="calc()" themeColor="primary" [svgIcon]="calculatorIcon"></button>
          </td>
          <td colspan="4"></td>
          <td>
            <button kendoButton (click)="addRow()" themeColor="primary" [svgIcon]="plusIcon"></button>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <kendo-formerror *ngIf="gluSpecForm?.errors?.sumSmallerThanMin" translate>
    GlulamSpec.SumNotInRange
  </kendo-formerror>
  <kendo-formerror *ngIf="gluSpecForm?.errors?.onlyOneHightDep" translate>
    GlulamSpec.MoreThanOneHeightDependant
  </kendo-formerror>
</form>
<kendo-dialog-actions kendo-dialog-action layout="end">
  <button kendoButton (click)="onCancel()" themeColor="error">
    {{ 'App.Cancel' | translate }}
  </button>
  <button kendoButton type="submit" [disabled]="(!gluSpecForm?.valid)" form="gluSpecForm" [primary]="true">
    {{ 'App.Ok' | translate }}
  </button>
  <div style="align-items: center;">
    <kendo-loader *ngIf="loading"></kendo-loader>
  </div>
</kendo-dialog-actions>
