import { Component, OnInit } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { OptinexUserDTO, UserService } from '@app/core/services/http-services/common/user.service';
import { SVGIcon, heartIcon, pencilIcon } from '@progress/kendo-svg-icons';

import { AddEvent } from '@progress/kendo-angular-grid';
import { TranslateService } from '@ngx-translate/core';
import { UserFormComponent } from '../user-form/user-form.component';
import { UserQueryService } from '@app/core/services/http-services/common/user-query.service';

@Component({
  selector: 'app-user-admin',
  templateUrl: './user-admin.component.html',
  styleUrls: ['./user-admin.component.css']
})
export class UserAdminComponent implements OnInit {
  public users: UserQueryService;

  public pencilIcon:SVGIcon = pencilIcon;
  public heartIcon:SVGIcon = heartIcon;

  public constructor(
    public userQueryService: UserQueryService,
    public dialogService:DialogService,
    public translateService:TranslateService,
    public userService:UserService
  ) { }

  ngOnInit(): void {

    this.users = this.userQueryService;

    this.users.query();
  }

  async onEdit(ev:AddEvent){
    console.log(ev);

    const dialogRef: DialogRef = this.dialogService.open({
      content: UserFormComponent,
      minWidth: '600px'
    });

    const userForm = dialogRef.content.instance as UserFormComponent;
    await userForm.setupForm(ev.dataItem as OptinexUserDTO);
  }

  heal(){
    this.userService.heal().subscribe();
  }
}
