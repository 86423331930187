<kendo-dialog-titlebar>{{ 'UserAdmin.EditUser' | translate }}</kendo-dialog-titlebar>
<form
    [formGroup]="from"
    *ngIf="from"
    id="userForm"
    (ngSubmit)="onSave()"
    class="col"
>
    <kendo-formfield>
        <kendo-label>{{'UserAdmin.UserId' | translate }}</kendo-label>
        <kendo-textbox formControlName="userId"></kendo-textbox>
    </kendo-formfield>
    <kendo-formfield>
        <kendo-label>{{'UserAdmin.EntraId' | translate }}</kendo-label>
        <kendo-textbox
            [readonly]="true"
            formControlName="entraId"
        ></kendo-textbox>
    </kendo-formfield>
    <kendo-formfield>
        <kendo-label>{{'UserAdmin.Sites' | translate }}</kendo-label>
        <kendo-multiselect
            [data]="sites | async"
            textField="siteName"
            valueField="siteId"
            [valuePrimitive]="true"
            formControlName="sites"
        ></kendo-multiselect>
    </kendo-formfield>
    <kendo-formfield>
        <kendo-label>{{'UserAdmin.Mashines' | translate }}</kendo-label>
        <kendo-multiselect
            [data]="mashines"
            textField="name"
            valueField="machineId"
            [valuePrimitive]="true"
            formControlName="machines"
        ></kendo-multiselect>
    </kendo-formfield>
    <div class="row role-row">
        <b>
            {{ 'UserAdmin.SingleTenantAdmin' | translate }}
        </b>
        <div class="flex-grow-1"></div>
        <kendo-loader *ngIf="singleTenantLoading"></kendo-loader>
        <button
            kendoButton
            [svgIcon]="getIcon(user.isSingleTenantAdmin)"
            [themeColor]="user.isSingleTenantAdmin ? 'success' : 'error'"
            (click)="toggleSingleTenantAdminRole()"
            type="button"
            size="small"
        ></button>
    </div>
    <div class="row role-row">
        <b>{{ 'UserAdmin.Developer' | translate }}</b>
        <div class="flex-grow-1"></div>
        <kendo-loader *ngIf="developerLoading"></kendo-loader>
        <button
            kendoButton
            [svgIcon]="getIcon(user.isDeveloper)"
            [themeColor]="user.isDeveloper ? 'success' : 'error'"
            (click)="toggleDeveloperRole()"
            type="button"
            size="small"
        ></button>
    </div>
    <div class="row role-row">
        <b>{{ 'UserAdmin.Planner' | translate }}</b>
        <div class="flex-grow-1"></div>
        <kendo-loader *ngIf="plannerLoading"></kendo-loader>
        <button
            kendoButton
            [svgIcon]="user.isPlanner ? checkIcon : xIcon"
            [themeColor]="user.isPlanner ? 'success' : 'error'"
            (click)="togglePlannerRole()"
            type="button"
            size="small"
        ></button>
    </div>
    <div class="row role-row">
        <b>{{ 'UserAdmin.Operator' | translate }}</b>
        <div class="flex-grow-1"></div>
        <kendo-loader *ngIf="operatorLoading"></kendo-loader>
        <button
            kendoButton
            [svgIcon]="user.isOperator ? checkIcon : xIcon"
            [themeColor]="user.isOperator ? 'success' : 'error'"
            (click)="toggleOperatorRole()"
            type="button"
            size="small"
        ></button>
    </div>
</form>
<kendo-dialog-actions layout="end">
    <button
        kendoButton
        form="userForm"
        themeColor="primary"
        [disabled]="!from.valid"
        [size]="'large'"
        [svgIcon]="saveIcon"
    ></button>
</kendo-dialog-actions>
