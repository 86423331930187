<kendo-dialog-titlebar kendo-dialog-title>
  {{ 'Operative.GlulamModels' | translate }}
</kendo-dialog-titlebar>

<div class="container">
  <section>
    {{ 'OrderViewTranslation.Order' | translate }} : {{ data.orderId }}
    {{ 'OrderViewTranslation.OrderRow' | translate }} : {{ data.orderRowId }}
    <div style="flex-grow: 1;"></div>
    <b> {{progressingMsg}} </b>
  </section>
  <div>
    <kendo-grid [data]="selectionLine" [resizable]="true" [columnMenu]="false" [filterable]="false"
      [loading]="loading" [size]="'small'">
      <kendo-grid-column field="level" title="{{ 'App.level' | translate }}" [width]="25">
      </kendo-grid-column>
      <kendo-grid-column field="productDesc" title="{{ 'AoT.ProductDesc' | translate }}" [width]="50">
      </kendo-grid-column>
      <kendo-grid-column field="selectedActvityGroup" title="{{ 'AoT.selectedActvityGroup' | translate }}" [width]="50">
      </kendo-grid-column>
      <kendo-grid-column field="activityIndex" title="{{ 'App.Activity' | translate }}" [width]="50">
        <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-dropdownlist [data]="dataItem.activitys" [(value)]="dataItem.selectedActvity" textField="description"
              valueField="index" [valuePrimitive]="true" [disabled]="noSelection(dataItem)"
              (valueChange)="activityChange($event, dataItem)" [defaultItem]="getDefaultItem()">
            </kendo-dropdownlist>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>

</div>

 <kendo-dialog-actions kendo-dialog-action>
  <div style="flex-grow:1"></div>
  <div>
    <button kendoButton themeColor="error" type="button" class="closebtn" (click)="close(false)">
      {{"App.Close" | translate }}
    </button>
    <button kendoButton themeColor="primary" type="submit" (click)="save()" class="float-right"
      [disabled]="disableGenerateBtn">
      {{"Operative.Generate" | translate}}
    </button>
  </div>
  <div style="align-items: center;">
    <kendo-loader *ngIf="loading"></kendo-loader>
  </div>
</kendo-dialog-actions>
