import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { IUserRoleRequestDTO, OptinexUserDTO, UserService } from '@app/core/services/http-services/common/user.service';
import { SVGIcon, checkIcon, saveIcon, xIcon, lockIcon } from '@progress/kendo-svg-icons';

import { Component } from '@angular/core';
import { IMachineDTO } from '@app/core/models/machineDTO';
import { IUserForm } from '@app/core/models/forms/app/user-form';
import { MachineService } from '@app/core/services/http-services/operative/machine.service';
import { SiteQueryService } from '@app/core/services/http-services/app/site-query.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.css']
})
export class UserFormComponent extends DialogContentBase{

  private singleTenantAdmin:string = 'SingleTenantAdmin';
  private developer:string = 'Developer';
  private Planner:string = 'Planner';
  private operator:string = 'Operator';

  public from:FormGroup<IUserForm> = null;

  applicationUser:OptinexUserDTO;
  user:OptinexUserDTO;
  sites:any;
  mashines:IMachineDTO[];

  checkIcon:SVGIcon = checkIcon;
  xIcon:SVGIcon = xIcon;
  saveIcon:SVGIcon = saveIcon;
  lockIcon:SVGIcon = lockIcon;

  plannerLoading: boolean =  false;
  operatorLoading: boolean =  false;
  singleTenantLoading: boolean =  false;
  developerLoading: boolean =  false;

  public constructor(
    dialog: DialogRef,
    public userService: UserService,
    public siteQueryService: SiteQueryService,
    public machineService:MachineService
  ) {

    super(dialog)

   }

  async setupForm(eventUser:OptinexUserDTO){

    this.applicationUser = await this.userService.getUser();

    this.user = await firstValueFrom(this.userService.get(eventUser.entraId));

    if(!this.user){
      this.dialog.close();
    }

    console.log(this.user);

    this.sites = this.siteQueryService;

    this.siteQueryService.query();

    this.mashines = (await firstValueFrom(this.machineService.query())).data


    var sideIds = this.user.userSites.map<string>(s => s.siteId)
    var machinesIds = this.user.userMachines.map<number>(s => s.machineId)

    this.from = new FormGroup<IUserForm>({
      userId: new FormControl<string>(eventUser.userId),
      entraId: new FormControl<string>(eventUser.entraId),
      machines: new FormControl(machinesIds),
      sites: new FormControl(sideIds),
    })
  }

  async toggleSingleTenantAdminRole(){

    if(this.singleTenantLoading || !this.applicationUser.isCrossTenantAdmin){
      return true
    }

    this.singleTenantLoading = true;

    const req:IUserRoleRequestDTO = <IUserRoleRequestDTO> {
      role: this.singleTenantAdmin,
      userId: this.user.entraId
    }

    if(this.user.isSingleTenantAdmin){
      await firstValueFrom(this.userService.revokeMemberRole(req));
      this.user.isSingleTenantAdmin = false;
    } else{
      await firstValueFrom(this.userService.addMemberToRole(req));
      this.user.isSingleTenantAdmin = true;
    }

    this.singleTenantLoading = false;
  }

  async toggleDeveloperRole(){

    if(this.developerLoading || !this.applicationUser.isCrossTenantAdmin){
      return true
    }

    this.developerLoading = true;

    const req:IUserRoleRequestDTO = <IUserRoleRequestDTO> {
      role: this.developer,
      userId: this.user.entraId
    }

    if(this.user.isDeveloper){
      await firstValueFrom(this.userService.revokeMemberRole(req));
      this.user.isDeveloper = false;
    } else{
      await firstValueFrom(this.userService.addMemberToRole(req));
      this.user.isDeveloper = true;
    }

    this.developerLoading = false;
  }

  async togglePlannerRole(){

    if(this.plannerLoading){
      return true
    }

    this.plannerLoading = true;

    const req:IUserRoleRequestDTO = <IUserRoleRequestDTO> {
      role: this.Planner,
      userId: this.user.entraId
    }

    if(this.user.isPlanner){
      await firstValueFrom(this.userService.revokeMemberRole(req));
      this.user.isPlanner = false;
    } else{
      await firstValueFrom(this.userService.addMemberToRole(req));
      this.user.isPlanner = true;
    }

    this.plannerLoading = false;
  }

  async toggleOperatorRole(){

    if(this.operatorLoading){
      return true
    }

    this.operatorLoading = true;

    const req:IUserRoleRequestDTO = <IUserRoleRequestDTO> {
      role: this.operator,
      userId: this.user.entraId
    }

    if(this.user.isOperator){
      await firstValueFrom(this.userService.revokeMemberRole(req));
      this.user.isOperator = false;
    } else{
      await firstValueFrom(this.userService.addMemberToRole(req));
      this.user.isOperator = true;
    }

    this.operatorLoading = false;
  }

  onSave(){
    this.user.machinesIds = this.from.value.machines;
    this.user.sitesIds = this.from.value.sites;

    this.userService.save(this.user.entraId, this.user).subscribe(() => {
      this.dialog.close();
    });
  }

  getIcon(isAuth:boolean):SVGIcon{
    if(!this.applicationUser.isGlobalAdmin){
      return this.lockIcon;
    }
    return isAuth ? checkIcon : xIcon
  }
}
