import { Component, Output, EventEmitter, Inject, AfterViewInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ConfigurationService } from '@app/core/services/custom-services/configuration.service';
import { Observable, timer, Subscription, Subject, takeUntil } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StepType } from '@progress/kendo-angular-layout';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { OptiInputModel, StaticalOptimizationOrder, StaticalOptimizationResponce, OptimizationType } from '@app/core/models/statical-optimization-order.model';
import { WatsonDeployedModel } from '@app/core/models/watson-deployed.model';
import { WatsonRunStatus, WatsonSolveStatus, WatsonStatus } from '@app/core/models/watson-status';
import { OptimazationService } from '@app/core/services/http-services/gluelam/optimazation.service';
import { AdminService } from '@app/core/services/http-services/admin/admin.service';
import { RunJobService } from '@app/core/services/http-services/run-job/run-job.service';
import { WatsonDataSetEnum } from '@app/core/models/watson-dataset.enum';
import { IMachineDTO } from '@app/core/models/machineDTO';
import { BeamMaterialGlueSetCreator } from '@app/core/models/beam-material-glue-set.model';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';

marker('App.SaveSettingMsg');

@Component({
  selector: 'statical-optimazation-order',
  templateUrl: './statical-optimization-order.component.html',
  styleUrls: ['./statical-optimization-order.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class StaticalOptimazationOrderEditor extends DialogContentBase implements AfterViewInit, OnDestroy {
   form: FormGroup = new FormGroup({});
   optimizationTypes: OptimizationType[] = [];
   watsonDeployedModels: WatsonDeployedModel[] = [];
   preActiveCounter = 0;
   optiActiveCounter = 0;
   getResultCounter = 0;
   elapsedSec: number;
   JobUrl: string;
  selectedMachine: IMachineDTO;
  jobState: WatsonStatus;
  NumberOfSurgestins: number[] = [1, 2, 5, 10, 100];
  numberOfLayers: number[] = [1, 2, 3];
   min = 0;
   max = 10;
   step = 1;

  stepType: StepType = 'full';
  current = 0;
  paramStepLable = 'Set Params';
  createStepLable = 'Crate Job';
  optiStepLable = 'Run Optimization';
  resultStepLable = 'Get Result';
  noResStepLable = 'No Res';

  public optiSteps = [
    { label: this.paramStepLable, icon: '', isValid: true, time: 10, state: null, solveStatus: null },
    { label: this.createStepLable, icon: '', isValid: true },
    { label: this.optiStepLable, isValid: true },
    { label: this.noResStepLable, isValid: false, optional: true },
    { label: this.resultStepLable, isValid: true }
  ];

  private pollCounter: Observable<number>;
  private timeCounter: Observable<number>;
  private counterSub: Subscription;
  private timeCounterSub: Subscription;
  private numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  title = (value: number): string => {
   return `${this.numbers[value]}`;
 };
 private destroy$: Subject<void> = new Subject<void>();

  @Output() optimizationResult = new EventEmitter<StaticalOptimizationResponce>();

  constructor(
    private configService: ConfigurationService,
    private optimazationService: OptimazationService,
    private dialogRef: DialogRef,
    private adminService: AdminService,
    @Inject(DialogRef) public input: OptiInputModel,
    private runJobService: RunJobService,
    private readonly notificationService: AppNotificationService,
  ) {
    super(dialogRef)
    this.form = new FormGroup({
      beamLamminaDimensionID: new FormControl<number>(0, [Validators.required]),
      maxGluePlanLength: new FormControl<number>(0, [Validators.required]),
      maxPressHight: new FormControl<number>(2000, [Validators.required]),
      minPressHight: new FormControl<number>(100, [Validators.required]),
      maxPressLength: new FormControl<number>(0, [Validators.required]),
      maxPressWidth: new FormControl<number>(0, [Validators.required]),
      maxBeamInBeamMaterial: new FormControl<number>(5, [Validators.required]),
      weightFactorFewOrdersPerBatch: new FormControl<number>(0, [Validators.required]),
      maxGlueplans: new FormControl<number>(5, [Validators.required]),
      maxGluesets: new FormControl<number>(10, [Validators.required]),
      maxLayers: new FormControl<number>(1, [Validators.required]),
      deployedModelFile: new FormControl<string>(null, []),
      deployedModelId: new FormControl<number>(null, [Validators.required]),
      maxSolveTime: new FormControl<number>(90, [Validators.required]),
      machineId: new FormControl<number>(this.input.machine?.machineId)
    });
  }

  override ngAfterViewInit(): void {
    this.pollCounter = timer(0, 3000); // polling
    this.timeCounter = timer(0, 100); // progres
    this.selectedMachine = this.input.machine;
    this.getDeployedModels(this.configService.WATSON_MODEL_FILE_ID);
    this.form.get('machineId').patchValue(this.input.machine?.machineId);
    this.form.get('machineId').disable();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getCsv() {
    this.current = 0;
    this.StartTimeCounter();
    const oo = this.getOpt();
    this.optimazationService.getStaticalOptimizationData(oo)
      .pipe(takeUntil(this.destroy$)).subscribe((response: Blob) => {
        const blob = new Blob([response], { type: 'application/zip' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'OptimizationData.zip';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
  }

  runOptimazation() {
    this.form.disable();

    this.current = 1;

    this.StartTimeCounter();

    const oo = this.getOpt();

    if (oo.demands.length > 0) {
      const toLongRows: string[] = [];

      if (toLongRows.length > 0) {
        this.notificationService.notifyWarningAppChanel(`Dessa rader är kortare än max längd${toLongRows.join(',')}`);
      }

      this.notificationService.notifyInfoAppChanel('Optimization started');

      this.optimazationService.staticalOptimazationStart(oo).pipe(takeUntil(this.destroy$)).subscribe((ret) => {
        this.JobUrl = ret.jobId;
        this.jobState = ret;
        this.StartJobPole();
        this.PollState();
      });
    } else {
      this.showError('Inga Rader Valda, Välj Rader!');
    }
  }

  close() {
    this.StopCounters();
    this.dialogRef.close();
  }

  onMachineSelected(m: IMachineDTO) {
    if (!m) {
      return;
    }

    if (m.maxDesiredPressLength) {
      this.form.patchValue({
        maxGluePlanLength: m.maxDesiredPressLength
      });
    }

    if (m.maxPressLength) {
      this.form.patchValue({
        maxPressLength: m.maxPressLength
      });
    }

    if (m.maxPressWidth) {
      this.form.patchValue({
        maxPressWidth: m.maxPressWidth
      });
    }

    if (m.minDesiredPressHight) {
      this.form.patchValue({
        minPressHight: m.minDesiredPressHight
      });
    }

    if (m.maxDesiredPressHight) {
      this.form.patchValue({
        maxPressHight: m.maxDesiredPressHight
      });
    }

    if (m.desiredNumberOfLayers) {
      this.form.patchValue({
        maxLayers: m.desiredNumberOfLayers > 3 ? 3 : m.desiredNumberOfLayers
      });
    }
  }

  private  getOpt(): StaticalOptimizationOrder {
    const o = new StaticalOptimizationOrder(
      this.input.BeamLamminaDimension.beamLamminaDimensionID,
      this.form.value.maxPressLength,
      this.form.value.maxPressHight,
      this.form.value.minPressHight,
      this.form.value.maxPressWidth,
      this.form.value.maxGluePlanLength,
      this.form.value.maxBeamInBeamMaterial,
      this.form.value.maxGlueplans,
      this.form.value.maxGluesets,
      this.form.value.maxLayers,
      this.form.value.maxSolveTime,
      this.form.getRawValue().machineId,
      this.form.value.deployedModelFile,
      this.form.value.deployedModelId
    );

    o.demands = this.input.BeamOrderLines;
    o.demands.forEach((x) => {
      x.qty =
        x.transportPackageAllocation?.qty + x.transportPackageAllocation?.unitsExtra - (x.QtySumUI + x.QtyPlanned);
    });

    return o;
  }

  private StartTimeCounter() {
    this.timeCounterSub = this.timeCounter.pipe(takeUntil(this.destroy$)).subscribe((t) => {
      this.elapsedSec = t / 10;

      if (this.current === 1) {
        this.preActiveCounter = this.elapsedSec;
        this.optiSteps[1].time = Math.floor(this.preActiveCounter);
      }
      if (this.current === 2) {
        this.optiActiveCounter = this.elapsedSec - this.preActiveCounter;
        this.optiSteps[2].time = Math.floor(this.optiActiveCounter);
      }
      if (this.current === 4) {
        this.getResultCounter = this.elapsedSec - this.preActiveCounter - this.optiActiveCounter;
        this.optiSteps[2].time = Math.floor(this.getResultCounter);
      }
    });
  }

 private StartJobPole() {
    this.counterSub = this.pollCounter.pipe(takeUntil(this.destroy$)).subscribe((n) => {
      if (this.JobUrl) {
        this.PollState();
      }
    });
  }

  private PollState() {
    this.runJobService.PollState(this.JobUrl).pipe(takeUntil(this.destroy$)).subscribe(
      (d) => {
        const [s, state] = d;
        this.jobState = s;
        console.warn(s.status);
        switch (state) {
          case -1:
            break;
          case 1:
            this.current = 1;
            break;
          case 2:
            this.current = 2;
            break;
          case 3:
            this.StopCounters();
            this.current = 3;
            this.optiSteps[3].state = `${s.status} ${s.solveStatus}`;
            break;
          case 4:
            this.current = 4;
            this.optiSteps[4].state = s.status + s.solveStatus;
            this.getResult();
            this.StopCounters();
            break;
          case 5:
            this.current = 3;
            this.optiSteps[3].state = s.status + s.solveStatus;
            this.StopCounters();
            break;
        }
      },
      (e) => {
        this.StopCounters();
        this.current = 3;
        this.jobState.status = WatsonRunStatus.appError;
        this.jobState.solveStatus = WatsonSolveStatus.appError;
      }
    );
  }
 private getResult() {
    this.optimazationService.getStaticalOptimazationResult(this.getOpt(), this.JobUrl).pipe(takeUntil(this.destroy$)).subscribe((r) => {
      if (r.errorMessage !== '') {
        let data: any[] = [];
        r.gluePlans.forEach((a) => {
          data = a.glueSets.map((response) =>
            BeamMaterialGlueSetCreator.FromJson(
              response,
              this.configService,
              true
            )
          );
          a.glueSets = [];
          a.glueSets.push(...data.filter(x => x.layers.length > 0));
        });
        this.notificationService.notifyInfoAppChanel(`Job Completed, total runtime ${this.elapsedSec}`);
        this.dialogRef.close(r);
      } else {
        this.notificationService.notifyErrorAppChanel(r.errorMessage);
      }
      this.StopCounters();
    });
  }

  private StopCounters() {
    if (this.counterSub)
      this.counterSub.unsubscribe();
    if (this.timeCounterSub)
      this.timeCounterSub.unsubscribe();
  }



  private showWarning(msg: string) {
    // this._snackBar.open(msg);
  }

  private ShowInfo(msg: string) {
    // this._snackBar.open(msg);
  }

  private showError(msg: string) {
    // this._snackBar.open(msg);
  }

  private getDeployedModels(customDeployedModelId: string) {
    this.adminService.getWatsonDeployments(WatsonDataSetEnum.GlulamStatical).pipe(takeUntil(this.destroy$)).subscribe((d) => {
      this.watsonDeployedModels = d;
      const deployedModelId = customDeployedModelId ?? this.watsonDeployedModels[0].Id;
      this.form.get('deployedModelId').patchValue(deployedModelId);
    });
  }
}
